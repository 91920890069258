import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {CustomButtonComponent} from "../custom-button/custom-button.component";
import {MatCheckbox, MatCheckboxChange} from "@angular/material/checkbox";
import {NgIf} from "@angular/common";
// import {REMOVE_RACE_WITHOUT_CONFIRMATION} from "../../../constants";

export interface DialogData {
  headerTitle: string;
  contentText: string;
  submitBtnText: string
  onSubmit: () => void;
  onCancel: () => void;
  showCheckbox: boolean
}

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [
    MatDialogActions,
    MatDialogTitle,
    MatDialogContent,
    MatButton,
    CustomButtonComponent,
    MatCheckbox,
    NgIf
  ],
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss'
})
export class DialogComponent {
  private checkboxChecked: boolean = false;
  public showSubmitButton: boolean = !!this.data.onSubmit

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onSubmit(): void {
    this.data.onSubmit();
    this.dialogRef.close();
    // localStorage.setItem(REMOVE_RACE_WITHOUT_CONFIRMATION, JSON.stringify(this.checkboxChecked))
  }

  onCancel(): void {
    this.data.onCancel();
    this.dialogRef.close();
  }

  handleCheckboxChange(event: MatCheckboxChange) {
    this.checkboxChecked = event.checked
  }
}
