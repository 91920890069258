import { Component, OnInit } from '@angular/core';
import { KeycloakService } from "keycloak-angular";

@Component({
  selector: 'app-web-login',
  standalone: true,
  imports: [],
  templateUrl: './web-login.component.html',
  styleUrl: './web-login.component.scss'
})
export class WebLoginComponent implements OnInit {
  constructor(private keycloakService: KeycloakService) {}

  async ngOnInit() {
    const isLogged = await this.keycloakService.isLoggedIn();
    if (isLogged) {
      const parentOrigin = new URL(document?.referrer)?.origin;
      const token = this.keycloakService.getToken();
      const dataToSend = { message: 'token', value: token };
      window.parent.postMessage(dataToSend, parentOrigin);
    } else {
      this.keycloakService.login();
    }
  }
}
