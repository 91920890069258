import { Component, OnInit } from '@angular/core';
import { provideNgxMask } from 'ngx-mask';
import { KeycloakService } from "keycloak-angular";

@Component({
  selector: 'app-keycloak-auth-callback',
  standalone: true,
  imports: [
  ],
  templateUrl: './keycloak-auth-callback.component.html',
  providers: [provideNgxMask()],
})
export class KeycloakAuthCallbackComponent implements OnInit {
  constructor(private keycloakService: KeycloakService,) {}

  async ngOnInit() {
    const token = await this.keycloakService.getToken();
    window?.opener?.postMessage({ type: 'keycloak-token', token }, '*');
    window.close();
  }
}
