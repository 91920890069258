import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { provideNgxMask } from 'ngx-mask';
import { OidcServiceService } from "../../services/oidcService/oidc-service.service";
import {combineLatest} from "rxjs";
import {UserService} from "../../services/user/user.service";

@Component({
  selector: 'app-auth-callback',
  standalone: true,
  imports: [
  ],
  templateUrl: './auth-callback.component.html',
  providers: [provideNgxMask()],
})
export class AuthCallbackComponent implements OnInit {
  provider: string | null = null;
  code: string | null = null;
  oauth_token: string | null = null;
  oauth_verifier: string | null = null;
  constructor(private readonly oidcService: OidcServiceService, private router: ActivatedRoute, private userService: UserService) {}

  async ngOnInit() {
    combineLatest([
      this.router.paramMap,
      this.router.queryParamMap,
    ]).subscribe(([paramMap, queryParamMap]) => {
      this.provider = paramMap.get('provider');
      this.code = queryParamMap.get('code');
      this.oauth_token = queryParamMap.get('oauth_token');
      this.oauth_verifier = queryParamMap.get('oauth_verifier');
      this.getToken();
    });
  }

  async sendToken(data: { token_secret?: string, token: string, account_type: string}) {
    this.userService.sendActivityToken(data).subscribe(() => console.log('activity data sent'))
  }

  async getToken() {
    if (this.provider === 'garmin') {
      const garminOAuthTokenSecret = localStorage.getItem('garmin_oauth_token_secret');
      const garminOAuthToken = localStorage.getItem('garmin_oauth_token');
      if (garminOAuthTokenSecret && garminOAuthToken && this.oauth_verifier) {
        const redirectUrl = `${window.location.origin}/auth-callback/${this.provider}`;
        this.oidcService.getAccessToken(garminOAuthToken, garminOAuthTokenSecret, this.oauth_verifier, redirectUrl).subscribe((data) => {
          if (data?.accessToken && data?.accessTokenSecret && this.provider) {
            this.sendToken({
              account_type: this.provider,
              token: data.accessToken,
              token_secret: data?.accessTokenSecret,
            })
            // window?.opener?.postMessage({
            //   type: `${this.provider}_token`,
            //   token: data.accessToken,
            //   token_secret: data.accessTokenSecret,
            //   updated_at: Date.now(),
            // }, '*');
            // window.localStorage.setItem(`${this.provider}_token`, JSON.stringify({
            //   token: data.accessToken,
            //   token_secret: data.accessTokenSecret,
            //   updated_at: Date.now(),
            // }));
          } else {
            // window.localStorage.removeItem(`${this.provider}_token`);
            // window.localStorage.removeItem('athlete_data');
          }
          setTimeout(() => {
            window.close();
          }, 100)
        })
      }
    }
    if (this.code && this.provider) {
      this.oidcService.getNewAccessToken(this.code, this.provider).subscribe((data) => {
        if (data?.token && this.provider) {
          this.sendToken({
            account_type: this.provider,
            token: data.token,
          })
          // if (data?.athlete_data) {
          //   window.localStorage.setItem('athlete_data', JSON.stringify(data?.athlete_data));
          // }
          // window?.opener?.postMessage({
          //   type: `${data?.provider}_token`,
          //   token: data?.token,
          //   updated_at: Date.now(),
          //   refresh_token: data?.refresh_token
          // }, '*');
          // window.localStorage.setItem(`${data?.provider}_token`, JSON.stringify({
          //   token: data?.token,
          //   updated_at: Date.now(),
          //   refresh_token: data?.refresh_token
          // }));
        } else {
          // window.localStorage.removeItem(`${data?.provider}_token`);
          // window.localStorage.removeItem('athlete_data');
        }
        setTimeout(() => {
          window.close();
        }, 100)
      })
    }
  }
}
