import { KeycloakOptions } from 'keycloak-angular';
import {environment} from "./environments/environment";

export const keycloakOptions: KeycloakOptions = {
  config: {
    url: environment.keycloakUrl,
    realm: environment.keycloakRealm,
    clientId: environment.keycloakClientId,
  },
  // loadUserProfileAtStartUp: true,
  bearerPrefix: 'Bearer',
  enableBearerInterceptor: true,
  initOptions: {
    flow: 'implicit',
    checkLoginIframe: false,
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri:
      window.location.origin + '/assets/silent-check-sso.html',
      // 'http://127.0.0.1:8000/assets/silent-check-sso.html',
  },
  shouldUpdateToken(request) {
    // @ts-ignore
    return !request.headers.get('token-update') === 'false';
  }
};
