import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HeaderComponent} from "../../components/header/header.component";
import {ActivatedRoute, Router} from "@angular/router";
import {RaceService} from "../../services/race/race.service";
import {
  ChatErrorMessage,
  ChatMessage,
  ChatMessageLink,
  GpxWaypoints,
  MappedChatMessage,
  Race,
  RaceRadiusData, RawSttMessage,
  SegmentTracks, SttMessage,
  Track,
  TrackCoordinates
} from "../../../types/models";
import {ChatService} from "../../services/chat/chat.service";
import {
  AI_REQUEST_TIMEOUT,
  AI_REQUESTS,
  CHOOSE_SEGMENT_OPTION,
  INTERACTIVE_WIDGETS,
  RACE_ELEVATION_DATA,
  SET_RADIUS_OPTION,
  SHOW_MAP_OPTION
} from "../../../constants";
import {FormsModule} from "@angular/forms";
import {DatePipe, NgClass, NgForOf, NgIf, NgOptimizedImage, NgStyle} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatChip, MatChipSet} from "@angular/material/chips";
import {KeycloakService} from "keycloak-angular";
import {OidcServiceService} from "../../services/oidcService/oidc-service.service";
import {CustomButtonComponent} from "../../components/custom-button/custom-button.component";
import {ScreenResizeService} from "../../services/screenResive/screen-resize.service";
import {ElevationProfileComponent} from "../../components/elevation-profile/elevation-profile.component";
import {MapWithRadiusComponent} from "../../components/map-with-radius/map-with-radius.component";
import {RoutesMapComponent} from "../../components/routes-map/routes-map.component";
import {
  MatCell, MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef,
  MatRow, MatRowDef,
  MatTable, MatTableDataSource
} from "@angular/material/table";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {GoogleMapsServiceService} from "../../services/google-maps-service/google-maps-service.service";
import {RouteSwiperComponent} from "../../components/route-swiper/route-swiper.component";
import {downSampleBuffer, pcmEncode} from "../../helpers/audioStream";
import {setAsyncInterval, clearAsyncInterval} from "../../helpers/intervals";
import {MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatButton} from "@angular/material/button";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../../components/dialog/dialog.component";
import {InteractiveMapComponent} from "../../components/interactive-map/interactive-map.component";

type Coordinates = {
  lat: number,
  lng: number
}

type MappedPathOutput = {
  lat: number,
  lng: number,
  distance?: number,
  elevationGain?: number,
  totalSimilarity?: number,
}

export interface RacesTableData {
  weeks: string;
  runs_count: string;
  total_distance: string;
  longest_run: string;
}

@Component({
  selector: 'app-race-ai-chat',
  standalone: true,
  imports: [
    HeaderComponent,
    FormsModule,
    NgClass,
    NgForOf,
    NgOptimizedImage,
    MatIcon,
    DatePipe,
    NgIf,
    MatProgressSpinner,
    MatChip,
    MatChipSet,
    CustomButtonComponent,
    MatChipSet,
    ElevationProfileComponent,
    MapWithRadiusComponent,
    RoutesMapComponent,
    InteractiveMapComponent,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatHeaderRow,
    MatRow,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRowDef,
    MatRowDef,
    NgStyle,
    RouteSwiperComponent,
    MatMenu,
    MatMenuItem,
    MatButton,
    MatMenuTrigger,
    MatMenuContent
  ],
  templateUrl: './race-ai-chat.component.html',
  styleUrl: './race-ai-chat.component.scss'
})
export class RaceAiChatComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('chatContainer') chatContainer!: ElementRef;
  @Input() request_index: number | null = null;
  @Input() elevations_data: TrackCoordinates[] | null = [];
  @Input() isMobile: boolean = true;
  @Input() units: string = '';
  raceId: string | null = '';
  race: Race | null = null;
  requestIndex: number | null = null;
  messages: MappedChatMessage[] = [];
  userMessage: string = '';
  date: Date | string = '';
  chatError: string | null = '';
  isChatLoading: boolean = false;
  filteredAiPrompts: string[] = [];
  hasRunkeeperTokenProcessed = false;
  AIRequestError: boolean = false;
  elevationsData: TrackCoordinates[] | null = [];
  showRadiusMap: boolean = false;
  start_point: Coordinates | null = null;
  radius_center_point: Coordinates | null = null;
  segment_coordinates: { startPointIndex: number, endPointIndex: number } | null = null;
  race_radius: number = 0;
  path: Coordinates[][] | null = null;
  race_units: string = '';
  private loadingMessageTimeout: any = null;
  private boundOnRunkeeperToken: (event: StorageEvent) => void;
  dataSource: RacesTableData[] = [];
  displayedColumns: string[] = ['month', 'runs_count', 'total_distance', 'longest_run'];
  newDataSource: MatTableDataSource<{ [key: string]: string }>
  segment_tracks: SegmentTracks[] | null = [];
  showInput: boolean = false;
  isChatHistoryEmpty: boolean = false;
  segment_distance: number = 0;
  isMessageLoading: boolean = false;
  isSpeechProcessing: boolean = false;
  isRecording: boolean = false;
  speechStartTime: number = 0;
  deviceStream: MediaStream | null = null;
  speechQueue: { chunk: Float32Array, time: number }[] = [];
  speechQueueProcessingInterval: number = 0;
  sttTimeout: number = 1.0;
  submitSpeechTimeout: number = 0;
  audioContext: AudioContext | null = null;
  audioWorkletNode: AudioWorkletNode | null = null
  micStream: MediaStreamAudioSourceNode | null = null
  isMicSelectorOpened: boolean = false;
  audioDevices: MediaDeviceInfo[] = [];
  selectedDeviceId: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private raceService: RaceService,
    private chatService: ChatService,
    private readonly keycloakService: KeycloakService,
    private readonly oidcService: OidcServiceService,
    private screenSizeService: ScreenResizeService,
    private sanitizer: DomSanitizer,
    private googleMapsService: GoogleMapsServiceService,
    public dialog: MatDialog
  ) {
    this.newDataSource = new MatTableDataSource<{ [key: string]: string }>([]);
    this.boundOnRunkeeperToken = this.onRunkeeperToken.bind(this);
  }

  async ngOnInit() {
    const token = await this.keycloakService.getToken();
    if (!token) {
      await this.keycloakService.login()
    }

    const isAiChatPage = this.route.snapshot.routeConfig?.path?.includes('ai-chat');

    this.screenSizeService.getIsMobile().subscribe(isMobile => {
      if (isAiChatPage && !isMobile) {
        this.router.navigate(['my-races/race', this.raceId], { queryParams: { request_index: this.requestIndex } });
        return;
      }
    })

    this.initializeRaceId();
    if (this.raceId) {
      this.loadRaceData();
    }

    if (this.requestIndex) {
      if (this.requestIndex === -1) {
        this.filteredAiPrompts = AI_REQUESTS
      } else {
        // @ts-ignore
        this.filteredAiPrompts = AI_REQUESTS.filter((item, index) => index !== this.requestIndex - 1);
      }
    }

    window.addEventListener('storage', this.boundOnRunkeeperToken);
    await this.getAudioDevices();
    const deviceId = localStorage.getItem('microphone-device-id');
    if (this.audioDevices.find(d => d.deviceId === deviceId)) {
      this.selectedDeviceId = deviceId
    } else {
      this.selectedDeviceId = this.audioDevices[0].deviceId
    }
  }

  async ngAfterViewInit() {
    this.speechQueueProcessingInterval = setAsyncInterval(async () => {
      if (this.speechQueue.length && !this.isSpeechProcessing) {
        this.isSpeechProcessing = true

        this.speechQueue.shift()

        const currentTime = new Date().getTime()
        if (
            this.speechStartTime
            && (currentTime - this.speechStartTime) / 1000 > this.sttTimeout
        ) {
          this.speechStartTime = 0
          if (this.submitSpeechTimeout) {
            clearTimeout(this.submitSpeechTimeout)
          }
          this.stopMicRecording()
        }

        this.isSpeechProcessing = false
      }
    }, 1)
  }

  onRunkeeperToken(event: StorageEvent) {
    if (this.hasRunkeeperTokenProcessed) return;
    if (event.storageArea !== localStorage) return
    if ((event.key === 'runkeeper_token' || event.key === 'strava_token' || event.key === 'garmin_token') && event.newValue) {
      const runkeeperData = JSON.parse(event.newValue);
      if (runkeeperData.token) {
        const accountType = event.key.replace('_token', '');
        this.chatService.sendLinkAccountMessage(runkeeperData.token, accountType, runkeeperData.token_secret);
        this.hasRunkeeperTokenProcessed = true;
      }
      window.removeEventListener('storage', this.onRunkeeperToken)
    }
  }

  ngOnDestroy() {
    window.removeEventListener('storage', this.boundOnRunkeeperToken);
    clearAsyncInterval(this.speechQueueProcessingInterval)
    this.chatService.disconnect();
  }

  private initializeRaceId() {
    const elevationData = localStorage.getItem(RACE_ELEVATION_DATA) || '';
    const parsedElevationData = elevationData && this.isMobile ? JSON.parse(elevationData) : [];

    this.elevationsData = parsedElevationData || [];

    if (this.elevations_data?.length) {
      this.start_point = { lat: this.elevations_data[0].lat, lng: this.elevations_data[0].lon };
    }

    if (this.elevations_data?.length) {
      this.elevationsData = this.elevations_data;
    }

    if (this.units) {
      this.race_units = this.units;
    }

    this.route.paramMap.subscribe((params) => {
      this.raceId = params.get('race_id');
      this.requestIndex = Number(this.route.snapshot.paramMap.get('request')) || this.request_index || this.requestIndex || null;
      if (params?.get('units')) {
        this.race_units = params?.get('units') || ''
      }
    });
  }

  private loadRaceData() {
    this.raceService.getRaceById(this.raceId!).subscribe((response) => {
      this.race = response;
      this.initializeChat();
    });
  }

  private initializeChat() {
    this.chatService.init(this.raceId!).then(() => {
      this.isChatLoading = true;
      this.chatService.onConnectionReady().subscribe(() => {
        this.isChatLoading = false;
        this.loadChatMessages();
        this.subscribeToSttMessage();
        this.subscribeToRawSttMessage();
        this.loadChatHistory();
        this.subscribeToErrors()
      });
    });
  }

  private loadChatMessages() {
    this.chatService.getMessages().subscribe((message: ChatMessage) => {
      this.processIncomingMessage(message);
    });
  }

  private loadChatHistory() {
    if (this.requestIndex !== null) {
      this.chatService.getChatHistory().subscribe((data) => {
        this.processChatHistory(data);
      });
    }
  }

  private subscribeToErrors() {
    this.chatService.getError().subscribe((message: ChatErrorMessage) => {
      this.handleError(message);
    });
  }

  private subscribeToSttMessage() {
    this.chatService.getSttMessage().subscribe((message: SttMessage) => {
      this.userMessage = message.message
      this.sendMessage()
      this.stopMicRecording()
    })
  }

  private subscribeToRawSttMessage() {
    this.chatService.getRawSttMessage().subscribe((message: RawSttMessage) => {
      const alternatives = message.alternatives[0]
      this.userMessage = alternatives?.transcript
    })
  }

  private processIncomingMessage(message: ChatMessage) {
    this.isChatLoading = false;

    if (this.loadingMessageTimeout) {
      clearTimeout(this.loadingMessageTimeout);
      this.loadingMessageTimeout = null;
    }

    const lastMessage = this.messages.find((msg) => msg.loading && msg.is_system);

    if (lastMessage) {
      this.updateLastMessage(lastMessage, message);
    } else {
      this.addNewMessage(message);
    }

    setTimeout(() => {
      if (this.requestIndex === 1) {
        this.showInput = true;
      }
      this.scrollToBottom();
    }, 100)
  }

  private updateLastMessage(lastMessage: MappedChatMessage, message: ChatMessage) {
    this.isMessageLoading = false;
    lastMessage.loading = false;
    lastMessage.text = this.formatMessage(message.data?.message, message?.data?.links || []) || '';
    if (message.data?.options) {
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: false,
        sent_at: new Date(),
        options: message.data?.options || [],
      })
    }


    if (message.data?.gpx) {
      this.path = message?.data?.gpx.tracks.map(i => this.getMappedPath(i));

      if (this.path) {
        this.path.map((i: MappedPathOutput[], index) => {
          const photo = this.googleMapsService.getStreetViewPhotoUrl(i[0].lat, i[0].lng)
          this.segment_tracks?.push({
            photo_url: photo || '',
            segment: `Segment ${index + 1}`,
            elevationGain: i[0]?.elevationGain || 0,
            totalSimilarity: i[0]?.totalSimilarity || 0,
            distance: i[0].distance || 0,
          })
        })
      }

      this.messages.push({
        text: '',
        is_options_message: false,
        is_system: true,
        sent_at: new Date(),
        is_map: true,
        options: message.data?.options || [],
      })
    }

    if (message?.data?.widgets) {
      let options: string[] = [];

      if (message?.data?.widgets[0] === 'SEGMENT_PICKER') {
        options = [CHOOSE_SEGMENT_OPTION];
      }

      if (message?.data?.widgets[0] === 'RADIUS_PICKER') {
        if (this.radius_center_point) {
          options = [SET_RADIUS_OPTION];
        } else {
          options = [SHOW_MAP_OPTION];
        }
      }

      if (options?.length && message?.data?.widgets[0] === 'RADIUS_PICKER' && !this.radius_center_point) {
        this.sendWidgetOption(options)
      }
      this.messages.push({
        text: '',
        is_system: true,
        sent_at: new Date(),
        widget: message?.data?.widgets[0]
      })

      if ((options?.length && message?.data?.widgets[0] === 'SEGMENT_PICKER') || (message?.data?.widgets[0] === 'RADIUS_PICKER' && this.radius_center_point)) {
        this.sendWidgetOption(options)
      }
    }

    if (message?.data?.table?.length) {
      const table = this.convertTableToElementData(message?.data?.table)
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: true,
        sent_at: new Date(),
        table: table
      })
    }

    this.addGearMessages(message);
    this.addLinkAccountsMessage(message);
  }

  private addNewMessage(message: ChatMessage) {
    this.messages.push({
      text: this.formatMessage(message.data?.message, message?.data?.links || []),
      is_system: true,
      sent_at: new Date(),
    });

    if (message?.data?.options) {
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: false,
        sent_at: new Date(),
        options: message.data?.options || [],
      })
    }
    if (message?.data?.widgets) {
      let options: string[] = [];

      const mapWidgets = [
        INTERACTIVE_WIDGETS.MAP_START_POINT,
        INTERACTIVE_WIDGETS.MAP_FINISH_POINT,
        INTERACTIVE_WIDGETS.MAP_ROUTE,
        INTERACTIVE_WIDGETS.AID_STATIONS,
        INTERACTIVE_WIDGETS.MAP_POINTS_OF_INTEREST,
      ]
      if (mapWidgets.includes(message.data.widgets[0] as INTERACTIVE_WIDGETS)) {
        if (message.data?.gpx) {
          this.messages.push({
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(),
            is_map: true,
            gpx: message?.data?.gpx,
            widget: message.data.widgets[0],
            center_coordinate: message.data?.center_coordinate,
            options: message.data?.options || [],
          })
        }
      }
      const elevationWidgets = [
        INTERACTIVE_WIDGETS.ELEVATION_PROFILE,
      ]
      if (elevationWidgets.includes(message.data.widgets[0] as INTERACTIVE_WIDGETS)) {
        if (message.data?.gpx) {
          this.messages.push({
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(),
            is_elevation: true,
            gpx: message?.data?.gpx,
            widget: message.data.widgets[0],
            center_coordinate: message.data?.center_coordinate,
            options: message.data?.options || [],
          })
        }
      }

      if (message.data.widgets[0] === 'MAP_SEGMENT') {
        if (message.data?.gpx) {
          this.path = message?.data?.gpx.tracks.map(i => this.getMappedPath(i));
          this.messages.push({
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(),
            is_map: true,
            options: message.data?.options || [],
          })
        }
      }

      if (message?.data?.widgets[0] === 'SEGMENT_PICKER') {
        options = [CHOOSE_SEGMENT_OPTION];
      }

      if (message?.data?.widgets[0] === 'RADIUS_PICKER' && !this.radius_center_point) {
        if (this.radius_center_point) {
          options = [SET_RADIUS_OPTION];
        } else {
          options = [SHOW_MAP_OPTION];
        }
      }

      if (options?.length && message?.data?.widgets[0] === 'RADIUS_PICKER' && options[0] !== SET_RADIUS_OPTION) {
        this.sendWidgetOption(options)
      }

      this.messages.push({
        text: '',
        is_system: true,
        sent_at: new Date(),
        widget: message?.data?.widgets[0]
      })

      if ((options?.length && message?.data?.widgets[0] === 'SEGMENT_PICKER') || options[0] === SET_RADIUS_OPTION) {
        this.sendWidgetOption(options)
      }
    }

    if (message?.data?.table?.length) {
      const table = this.convertTableToElementData(message?.data?.table)
      this.messages.push({
        text: '',
        is_options_message: true,
        is_system: true,
        sent_at: new Date(),
        table: table
      })
    }

    // if (message?.data?.widgets) {
    //   return this.messages.push({
    //     text: '',
    //     is_system: true,
    //     sent_at: new Date(),
    //     widget: message?.data?.widgets[0]
    //   })
    // }

    this.addGearMessages(message);
    this.addLinkAccountsMessage(message);
  }

  getMappedPath(track: Track | GpxWaypoints): MappedPathOutput[] {
    if (track && track?.geometry && track?.properties) {
      const { distance, elevationGain, totalSimilarity } = track.properties;

      if (track?.properties?.distance && track?.properties?.elevationGain && track?.properties?.totalSimilarity) {
        return track.geometry.coordinates.map((i) => ({
          lat: i.lat,
          lng: i.lon,
          distance: Number(distance?.toFixed(1)) || 0,
          elevationGain: Number(elevationGain?.toFixed(0)) || 0,
          totalSimilarity: Number(totalSimilarity?.toFixed(0)) || 0
        }));
      } else {
        return track?.geometry?.coordinates.map(i => ({ lat: i.lat, lng: i.lon }))
      }
    } else {
      return [];
    }
  }

  sendWidgetOption(options: string[]) {
    this.messages.push({
      text: '',
      is_options_message: true,
      is_system: false,
      sent_at: new Date(),
      is_widget_options: true,
      options
    })
  }

  formatMessage(message: string, links: ChatMessageLink[]): SafeHtml {
    if (!message?.length) return '';

    let formattedMessage = message
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/\n/g, '<br>')
      .replace(/### (.*?)(<br>|$)/g, '<h3>$1</h3>');

    formattedMessage = formattedMessage.replace(/\[P(\d+)\]/g, (match, pIndex) => {
      const link = links.find(l => l.reference === parseInt(pIndex, 10));

      return link ? `
        <a href="${link.url}" target="_blank" style="position: relative">
            <img
                src="assets/images/external-link.svg"
                alt="External link"
                style="width: 16px; height: 16px; position: absolute; top: 0;margin-left: 6px; filter: invert(43%) sepia(13%) saturate(4555%) hue-rotate(206deg) brightness(103%) contrast(104%)"
            />
        </a>` : match;
    });

    return this.sanitizer.bypassSecurityTrustHtml(formattedMessage);
  }

  private addLinkAccountsMessage(message: ChatMessage) {
    if (message.data?.link_accounts?.length) {
      const linkAccountsOptions = message.data.link_accounts.map(i => ({ name: i.name, logo: `/assets/images/${i.name}-logo.svg` }))
      this.messages.push({
        text: this.formatMessage(message.data.message, message?.data?.links || []),
        sent_at: new Date(),
        link_accounts_options: linkAccountsOptions,
        is_system: true
      })
    }
  }

  private addGearMessages(message: ChatMessage) {
    if (message.data?.gears?.length) {
      const gearMessage = message.data.gears.map((gear) => {
        const gearLinkText = { text: `Explore ${gear.brand}`, link: gear?.link };
        const gearLabel = gear.name;

        return {
          text: gearLabel,
          link: gearLinkText,
          image: gear?.images ? gear?.images[0]?.file_url : ''
        }
      })

      this.messages.push({
        text: '',
        label: '',
        sent_at: new Date(),
        gearOptions: gearMessage,
        is_system: true
      })
    }
  }

  private processChatHistory(data: ChatMessage[]) {
    if (data?.length) {
      this.isChatHistoryEmpty = false;
      const formattedMessages = this.formatChatHistory(data);
      this.messages.push(...formattedMessages);

      if (this.requestIndex !== -1) {
        this.sendInitialMessage();
      }
    } else if (this.requestIndex && this.requestIndex !== -1) {
      this.sendInitialMessage();
    } else {
      this.isChatHistoryEmpty = true;
    }
    this.scrollToBottom();
  }

  convertTableToElementData(table: string[][]) {
    this.displayedColumns = table[0].map((header: string) => header);

    this.displayedColumns = table[0].map((header: string) => header);

    const rows = table.slice(1).map((row: string[]) => {
      const rowObj: { [key: string]: string } = {};
      row.forEach((cell: string, index: number) => {
        rowObj[this.displayedColumns[index]] = cell;
      });
      return rowObj;
    });

    if (this.newDataSource) {
      this.newDataSource.data = rows;
    }

    return rows
  }

  private formatChatHistory(data: ChatMessage[]) {
    return data.flatMap((i) => {
      const mapWidgets = [
        INTERACTIVE_WIDGETS.MAP_START_POINT,
        INTERACTIVE_WIDGETS.MAP_FINISH_POINT,
        INTERACTIVE_WIDGETS.MAP_ROUTE,
        INTERACTIVE_WIDGETS.AID_STATIONS,
        INTERACTIVE_WIDGETS.MAP_POINTS_OF_INTEREST,
      ]
      if (i?.data?.widgets?.length && mapWidgets.includes(i?.data?.widgets[0] as INTERACTIVE_WIDGETS)) {
        if (i.data?.gpx) {
          return {
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(i.sent_at),
            is_map: true,
            gpx: i?.data?.gpx,
            widget: i?.data?.widgets[0],
            loading: false,
          }
        }
      }

      const elevationWidgets = [
        INTERACTIVE_WIDGETS.ELEVATION_PROFILE,
      ]
      if (i?.data?.widgets?.length && elevationWidgets.includes(i?.data?.widgets[0] as INTERACTIVE_WIDGETS)) {
        if (i.data?.gpx) {
          return {
            text: '',
            is_options_message: false,
            is_system: true,
            sent_at: new Date(i.sent_at),
            is_elevation: true,
            gpx: i?.data?.gpx,
            widget: i?.data?.widgets[0],
            loading: false,
          }
        }
      }

      const baseMessage = {
        text: this.formatMessage(i.data?.message, i?.data?.links || []) || '',
        is_system: i.is_system,
        loading: false,
        sent_at: new Date(i.sent_at),
      };

      const gear = i?.data?.gears?.map((gear) => {
        const gearLinkText = { text: `Explore ${gear.brand}`, link: gear?.link };
        const gearLabel = gear.name;

        return {
          text: gearLabel,
          link: gearLinkText,
          image: gear?.images ? gear?.images[0]?.file_url : ''
        }
      })

      const gearMessage = [{
        text: '',
        label: '',
        sent_at: new Date(i.sent_at),
        gearOptions: gear,
        is_system: true
      }]
      return gearMessage ? [baseMessage, ...gearMessage] : baseMessage;
    });
  }

  private sendInitialMessage() {
    this.chatService.sendMessage(AI_REQUESTS[this.requestIndex! - 1]);
    this.messages.push({
      text: AI_REQUESTS[this.requestIndex! - 1],
      is_system: false,
      sent_at: new Date(),
    });
    this.scrollToBottom();
    this.addLoadingMessage();
  }

  private addLoadingMessage() {
    this.isMessageLoading = true;
    this.messages.push({ text: '', is_system: true, loading: true, sent_at: new Date() });

    this.loadingMessageTimeout = setTimeout(() => {
      this.removeLoadingMessage();
      this.AIRequestError = true;
    }, AI_REQUEST_TIMEOUT);
  }

  private handleError(message: ChatErrorMessage) {
    this.isChatLoading = false;
    this.chatError = message.error || 'Something went wrong.';
    this.removeLoadingMessage();
  }

  private removeLoadingMessage() {
    const lastMessage = this.messages.find((msg) => msg.loading && msg.is_system);
    if (lastMessage) {
      this.messages = this.messages.filter((msg) => msg !== lastMessage);
    }

    if (this.loadingMessageTimeout) {
      clearTimeout(this.loadingMessageTimeout);
      this.loadingMessageTimeout = null;
    }
  }

  scrollToBottom(): void {
    setTimeout(() => {
      this.chatContainer.nativeElement.scrollTo({
        top: this.chatContainer.nativeElement.scrollHeight,
        behavior: 'smooth',
      });
    }, 100);
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  onOptionClick(option: string) {
    if (option === CHOOSE_SEGMENT_OPTION || option === SHOW_MAP_OPTION || option === SET_RADIUS_OPTION) {
      if (option === CHOOSE_SEGMENT_OPTION && this.segment_coordinates) {
        this.chatService.sendSegmentCoordinatesMessage({ start_index: this.segment_coordinates?.startPointIndex, end_index: this.segment_coordinates?.endPointIndex, selected_distance: this.segment_distance });
      }

      if (option === SHOW_MAP_OPTION) {

        const lastMessageIndex = this.messages.length - 2;
        const lastMessage = this.messages[lastMessageIndex];

        if (lastMessage.options) {
          delete lastMessage.options;
          lastMessage.text = SHOW_MAP_OPTION
          lastMessage.is_options_message = false;
          lastMessage.is_widget_options = false;
        }

        this.messages[lastMessageIndex] = lastMessage;

        this.scrollToBottom();

        if (this.radius_center_point) {
          this.messages.push({
            text: '',
            is_options_message: true,
            is_system: false,
            sent_at: new Date(),
            is_widget_options: true,
            options: [SET_RADIUS_OPTION]
          })
          return;
        }
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.radius_center_point = { lat: position.coords.latitude, lng: position.coords.longitude }
            this.messages.push({
              text: '',
              is_options_message: true,
              is_system: false,
              sent_at: new Date(),
              is_widget_options: true,
              options: [SET_RADIUS_OPTION]
            })
          },
          (error) => {
            this.radius_center_point = { lat: this.start_point?.lat || 40.730610, lng: this.start_point?.lng || -73.935242 }
            this.messages.push({
              text: '',
              is_options_message: true,
              is_system: false,
              sent_at: new Date(),
              is_widget_options: true,
              options: [SET_RADIUS_OPTION]
            })
          },
          {
            enableHighAccuracy: true,
            maximumAge: 0
          }
        );
        return;
      }

      if (option === SET_RADIUS_OPTION && this.race_radius && this.radius_center_point) {
        const lastMessageIndex = this.messages.length - 1;

        const lastMessage = this.messages[lastMessageIndex];
        if (lastMessage.options) {
          delete lastMessage.options;
          lastMessage.text = SET_RADIUS_OPTION;
          lastMessage.is_options_message = false;
          lastMessage.is_widget_options = false;
        }

        this.messages[lastMessageIndex] = lastMessage;

        this.scrollToBottom();
        const data: RaceRadiusData = {
          radius: this.race_radius,
          point: { lat: this.radius_center_point.lat, lon: this.radius_center_point.lng }
        }
        this.chatService.sendMapRadiusMessage(data);
        this.addLoadingMessage();
        return;
      }
    }
    this.chatService.sendMessage(option);

    const lastMessageIndex = this.messages.length - 1;
    const lastMessage = this.messages[lastMessageIndex];

    if (lastMessage.options) {
      delete lastMessage.options;
    }

    this.messages[lastMessageIndex] = lastMessage;

    this.messages.push({ text: option, is_system: false, sent_at: new Date() });
    this.scrollToBottom();
    this.addLoadingMessage();
  }

  async setupMicStream() {
    if (!this.audioContext) {
      this.audioContext = new AudioContext()
      await this.audioContext.audioWorklet.addModule('/assets/js/audio-processor.js');
    }

    this.deviceStream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: this.selectedDeviceId ? {
        deviceId: this.selectedDeviceId,
      } :true,
    })

    this.audioWorkletNode = new AudioWorkletNode(this.audioContext, 'audio-processor');
    this.audioWorkletNode.port.onmessage = (event) => {
      const raw = event.data; // Отримання Float32Array
      if (raw === null) return
      const downSampledBuffer = downSampleBuffer(
          raw,
          undefined,
          16000,
      )
      const pcmEncodedBuffer = pcmEncode(downSampledBuffer)
      this.chatService.sendGoogleSttData(pcmEncodedBuffer)
    };
    this.micStream = this.audioContext.createMediaStreamSource(this.deviceStream);
    this.micStream.connect(this.audioWorkletNode);
    this.audioWorkletNode.connect(this.audioContext.destination);
  }

  private showMicDeniedDialog() {
    this.dialog.open(DialogComponent, {
      width: '300px',
      data: {
        headerTitle: 'Permissions request',
        contentText: 'Please grant access to the microphone first, to use voice input',
        submitBtnText: 'Request again',
        onCancel: () => {},
      }
    })
  }

  async startMicRecording() {
    let isAccessibleMic = false
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop());
      isAccessibleMic = true;
    } catch (error) {
      isAccessibleMic = false;
    }
    if (!isAccessibleMic) {
      return this.showMicDeniedDialog()
    }

    this.isRecording = true
    this.speechStartTime = 0;
    this.chatService.startGoogleStt()
    await this.setupMicStream()
  }

  stopMicRecording() {
    this.chatService.stopGoogleStt()
    this.deviceStream?.getAudioTracks().forEach((track) => {
      track.stop()
    })
    if (this.audioWorkletNode) {
      this.audioWorkletNode.disconnect();
    }
    if (this.micStream) {
      this.micStream.disconnect();
    }
    if (this.audioContext) {
      this.audioContext.close().then(() => {
        this.audioContext = null
      });
    }
    this.isRecording = false
  }


  sendMessage() {
    if (this.userMessage.trim()) {
      this.messages.push({ text: this.userMessage, is_system: false, sent_at: new Date() });
      this.chatService.sendMessage(this.userMessage);
      this.userMessage = '';
      this.scrollToBottom();
      this.addLoadingMessage();

      if (this.loadingMessageTimeout) {
        clearTimeout(this.loadingMessageTimeout);
        this.loadingMessageTimeout = null;
      }
    }
  }

  handleMicrophoneSelector(state: boolean) {
    this.isMicSelectorOpened = state
  }

  async getAudioDevices(): Promise<void> {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.audioDevices = devices.filter(device => device.kind === 'audioinput');
    } catch (error) {
      console.error('Error fetching audio devices:', error);
    }
  }

  selectDevice(deviceId: string | null): void {
    if (!deviceId) return
    this.selectedDeviceId = deviceId;
    localStorage.setItem('microphone-device-id', deviceId);
  }

  onBackClick() {
    this.chatService.disconnect();
    this.router.navigate(['my-races/race', this.raceId]);
  }

  onSettingsClick() {
    this.router.navigate(['profile/user-ai-settings']);
  }

  onAiRequestClick(index: number) {
    this.isChatHistoryEmpty = false;
    const message = this.filteredAiPrompts[index - 1];
    this.requestIndex = index;
    this.chatService.sendMessage(message);
    this.messages.push({ text: message, is_system: false, sent_at: new Date() });
    this.scrollToBottom();
    this.addLoadingMessage();
    this.removeOldOptions();

    if (index) {
      // @ts-ignore
      this.filteredAiPrompts = AI_REQUESTS.filter((item, index) => index !== this.requestIndex - 1);
    }
  }

  removeOldOptions() {
    this.messages.forEach(message => {
      if (message?.options) {
        delete message.options;
      }
    })
  }

  onFitnessClick(provider: string) {
    this.hasRunkeeperTokenProcessed = false;
    const message = { type: 'provider', value: provider };

    window.parent.postMessage(message, '*');
    this.oidcService.openAuthWindow(provider);
  }

  regenerateResponse() {
    this.AIRequestError = false;
    const message = this.messages[this.messages.length - 1];
    this.chatService.sendMessage(message.text.toString());
    this.messages.push({ text: message.text, is_system: false, sent_at: new Date() });
    this.scrollToBottom();
    this.addLoadingMessage();
  }

  onSegmentSelected(event: { startPointIndex: number, endPointIndex: number, segmentDistance: number } | null) {
    if (event && event?.startPointIndex < event?.endPointIndex) {
      this.segment_coordinates = { startPointIndex: event.startPointIndex, endPointIndex: event.endPointIndex };
      this.segment_distance = event?.segmentDistance;
    } else {
      this.segment_coordinates = null;
    }
  }

  onRadiusSelect(event: { radius: number, center: Coordinates }) {
    this.race_radius = event?.radius
    this.radius_center_point = event?.center
  }

  onSegmentOptionClick(option: string) {
    if (option === SHOW_MAP_OPTION) {
      this.showRadiusMap = true;
    }

    // if (option === CHOOSE_SEGMENT_OPTION) {}
  }
}
