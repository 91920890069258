import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Renderer2, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {MatFormField, MatPrefix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AiChatComponent} from "../ai-chat/ai-chat.component";
import {PromptsListComponent} from "../../components/prompts-list/prompts-list.component";
import {NgForOf, NgIf, NgStyle, TitleCasePipe} from "@angular/common";
import {SafeResourceUrl} from "@angular/platform-browser";
import { SELECTED_RACE_ID, WIDGET_PROMPTS_LIST } from "../../../constants";
import {RaceService} from "../../services/race/race.service";
import { Race, WidgetConfiguration } from "../../../types/models";
import {AuthDrawerComponent} from "../auth-drawer/auth-drawer.component";
import {MatOption, MatSelect} from "@angular/material/select";
import {AiChatWrapperComponent} from "../ai-chat-wrapper/ai-chat-wrapper.component";
import {ScreenResizeService} from "../../services/screenResive/screen-resize.service";
import {convertToTitleCase} from "../../helpers/convertToTitleCase";

type Coordinates = {
  lat: number,
  lng: number
}

@Component({
  selector: 'app-search-bar',
  standalone: true,
  imports: [
    MatFormField,
    MatIcon,
    MatInput,
    MatPrefix,
    ReactiveFormsModule,
    FormsModule,
    AiChatComponent,
    PromptsListComponent,
    NgIf,
    AuthDrawerComponent,
    MatSelect,
    MatOption,
    NgForOf,
    NgStyle,
    AiChatWrapperComponent,
    TitleCasePipe
  ],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SearchBarComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() event!: string;
  @Input() demo!: string;
  @Input() configuration!: string;
  isDrawerOpen = false;
  prompts: string[] = WIDGET_PROMPTS_LIST;
  selectedPrompt: string = '';
  isVoiceMode: boolean = false;
  keycloakLoginUrl: SafeResourceUrl = '';
  showLoginIframe: boolean = false;
  raceId: string = 'b2a2005b-ab5e-4c69-939b-e6a8fe63547e';
  race: Race | null = null;
  path: Coordinates[] | null = null;
  userMessage: string = '';
  isAIDrawerOpen: boolean = false;
  token: string = '';
  races: Race[] = [];
  selectedRace: string = '';
  error: string = ''
  raceTypes: { id: string, name: string, display_order: number }[] = [];
  isMobile: boolean = false;
  widgetConfiguration: WidgetConfiguration | null = null;
  promptColor: string = '#6271FF';

  constructor(
    private raceService: RaceService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private screenSizeService: ScreenResizeService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['configuration']) {
      if (this.configuration) {
        this.widgetConfiguration = JSON.parse(this.configuration);
        this.promptColor = this.widgetConfiguration?.style?.main_color || '#6271FF';
      }
    }
  }

  async ngOnInit() {
    const selectedRaceId = localStorage.getItem(SELECTED_RACE_ID);

    if (selectedRaceId) {
     this.selectedRace = selectedRaceId;
    }

    if (this.demo === 'true') return;

    await this.getEventRaces();
    this.loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDsoZfoY75xr-6bUPgVNrsgFsJ59u0Yohw&libraries&v=alpha&libraries=places,geometry,maps3d,marker')

    window.addEventListener('storage', (e) => this.onAuthStateChange(e))

    this.screenSizeService.getIsMobile().subscribe(isMobile => {
      this.isMobile = isMobile
    })
  }

  loadScript(src: string) {
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    script.async = true;


    document.head.appendChild(script);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const elements = document.querySelectorAll('[class*="api-load-alpha-banner"]');
      if (elements) {
        elements.forEach(element => {
          this.renderer.setStyle(element, 'display', 'none');
        });
      }
    }, 250)
  }

  async getEventRaces() {
    const myEventId = localStorage.getItem('my-event-id');
    let eventId = '';

    if (myEventId) {
      eventId = myEventId
    } else {
      eventId = this.event || 'e2f7b974-3c88-4c99-b827-8e90c60d2e00'
    }

    this.raceService.getEventRaces(eventId).subscribe(response => {
      if (response) {
        this.races = response?.races;

        const foundRace = this.races?.length ? this.races.find((i) => i.guid === this.selectedRace) : null;

        if (!foundRace) {
          this.selectRace(this.races[0].guid || '')
        }

        if (response?.widget_configuration) {
          this.widgetConfiguration = response?.widget_configuration;

          this.promptColor = this.widgetConfiguration?.style?.main_color || '#6271FF';
        }

        const mappedRaces = this.races.map((i) => {
          const orderConfig = response?.race_ordering_config?.find(j => j.guid === i.guid);

          return {
            id: i?.guid || '',
            name: convertToTitleCase(i?.race_type || ''),
            display_order: orderConfig?.order || 0
          }
        });

        this.raceTypes = mappedRaces.sort((a, b) => a.display_order - b.display_order);
      }
    })
  }

  async onAuthStateChange(event: StorageEvent) {
    if (event.storageArea === localStorage || event?.type === 'storage')   {
      if (event.key === 'success-login' && event.newValue) {
        if (event.newValue) {
          this.showLoginIframe = false;
          this.keycloakLoginUrl = '';
          this.isDrawerOpen = false;
          this.cdr.detectChanges();
          this.token = event.newValue;
          this.isAIDrawerOpen = true
        }
      }
    }
  }

  selectRace(id: string) {
    if (id) {
      this.selectedRace = id;
      localStorage.setItem(SELECTED_RACE_ID, id);
    }
  }

  sendMessage() {
    if (this.demo === 'true') return;
    if (this.userMessage.trim()) {
      this.isVoiceMode = false
      this.openDrawer(this.userMessage)
      this.userMessage = '';
    }
  }

  async openVoiceInput() {
    if (this.demo === 'true') return;

    let isAccessibleMic = false
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop());
      isAccessibleMic = true;
    } catch (error) {
      isAccessibleMic = false;
    }
    if (!isAccessibleMic) {
      this.error = 'Microphone usage has not permitted.'
      return
    }
    this.error = ''
    this.isVoiceMode = true
    this.openDrawer(this.userMessage)
  }

  handleChangeDrawerState(isOpen: boolean) {
    this.isDrawerOpen = isOpen
    if (this.showLoginIframe) {
      this.isDrawerOpen = isOpen
    } else {
      this.isAIDrawerOpen = isOpen
    }
  }

  selectDefaultPrompt(prompt: string) {
    this.isVoiceMode = false
    this.openDrawer(prompt)
  }

  openDrawer(prompt: string) {
    if (this.demo === 'true') return;
    this.selectedPrompt = prompt;
    if (this.showLoginIframe) {
      this.isDrawerOpen = !this.isDrawerOpen
    } else {
      this.isAIDrawerOpen = !this.isAIDrawerOpen
    }
  }
}
