import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {NgIf, NgStyle} from "@angular/common";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";
import {Race} from "../../../types/models";
import {ScreenResizeService} from "../../services/screenResive/screen-resize.service";

@Component({
  selector: 'app-ai-chat-wrapper',
  standalone: true,
  imports: [
    NgIf,
    NgStyle
  ],
  templateUrl: './ai-chat-wrapper.component.html',
  styleUrl: './ai-chat-wrapper.component.scss'
})
export class AiChatWrapperComponent implements OnInit, OnDestroy {
  @ViewChild('iframeContainer') iframeContainer!: ElementRef;
  @Input() isOpen: boolean = false;
  @Input() prompt: string = '';
  @Input() raceId: string = '';
  @Input() races: Race[] = [];
  @Input() raceTypes: { id: string, name: string, display_order: number }[] = [];
  @Input() isVoiceMode: boolean = false;
  @Input() mainColor: string = '#4C57BC';
  @Output() handleChangeDrawerState = new EventEmitter<boolean>();
  iframeSrc: SafeResourceUrl = ``;
  isMobile: boolean = false;

  constructor(private sanitizer: DomSanitizer, private screenSizeService: ScreenResizeService,) {}

  ngOnInit() {
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.parentUrl}/ai-chat`);
    // this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(` http://192.168.0.78:4200/ai-chat`);

    window.addEventListener('message', (event) => this.onMessage(event));

    this.screenSizeService.getIsMobile().subscribe(isMobile => {
      this.isMobile = isMobile
    })
  }

  ngOnDestroy() {
    window.removeEventListener('message', (event) => this.onMessage(event))
  }

  onMessage(event: MessageEvent ) {
    if (event.data.type === 'closeDrawer') {
      this.closeDrawer();
    }
    if (event.data.type === 'iframeReady') {
      this.initIframe()
    }
  }

  initIframe() {
    const iframe = this.iframeContainer.nativeElement as HTMLIFrameElement;
    if (!iframe) return;

    if (iframe?.contentWindow) {
      iframe.contentWindow.postMessage({ type: 'raceId', payload: this.raceId }, '*');
      iframe.contentWindow.postMessage({ type: 'races', payload: this.races }, '*');
      iframe.contentWindow.postMessage({ type: 'raceTypes', payload: this.raceTypes }, '*');
      iframe.contentWindow.postMessage({ type: 'voiceMode', payload: this.isVoiceMode }, '*');
      iframe.contentWindow.postMessage({ type: 'mainColor', payload: this.mainColor }, '*');
      if (this.prompt) {
        iframe.contentWindow.postMessage({ type: 'prompt', payload: this.prompt }, '*');
      }
    }
  }

  closeDrawer() {
    this.isOpen = false;
    this.handleChangeDrawerState.emit(false);
  }
}
